<template>
  <div>
    <nav class="nav-style">
      <div class="menu-item">
        <router-link to="/portfolio">portfolio</router-link>
      </div>
      <div class="menu-item">
        <router-link to="/atelier">atelier</router-link>
      </div>
      <div class="menu-item">
        <router-link class="link-style" to="/übermich">über mich</router-link>
      </div>
      <div class="menu-item">
        <router-link to="/anfahrt">anfahrt</router-link>
      </div>
      <div class="menu-item">
        <router-link to="/kontakt">kontakt</router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
    name: 'NaviGation',
    components:{
    },
    data() {
        return {
        }
    },
}
</script>

<style>
.nav-style {
  background-color: #203d36;
}
nav {
  font-family: Casta;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .menu-item {
  color: #ec5728;
  display: flex;
  text-align: center;
  padding: 5px 10px;
  position: relative;
  transition: 0.4s;
  border-bottom: 3px solid transparent;
}
nav a {
  color: #ec5728;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: antiquewhite;
  text-decoration: none;
}
nav .menu-item:hover {
  color: wheat;
  background-color: cadetblue;
  border-bottom-color: wheat;
}
</style>